import HttpService from '@/assets/javascripts/services/HttpService.js';

export default {
  async mergeUser(originUserIds, targetUserId) {
    const params = {
      origin_user_ids: originUserIds,
      target_user_id: targetUserId,
    };

    return HttpService.post('/api/base_user/merge_users', params);
  },

  async reactivateUser(baseUserId) {
    return HttpService.post(`/api/base_user/${baseUserId}/reactivate`);
  },
};
