import TokenService from '@/assets/javascripts/services/TokenService';
import TenantAPI from '@/api/tenantAPI';

const TENANT_LOCAL_STORAGE_KEY = 'currentTenant';
const DEFAULT_TENANT_SLUG = 'quero-bolsa';

const savedTenant = getParsedJsonFromLocalStorage(TENANT_LOCAL_STORAGE_KEY);

export const state = () => ({
  id: localStorage.getItem('adminId') || null,
  token: localStorage.getItem('token') || null,
  email: null,
  name: null,
  tenantsList: [],
  currentTenant: savedTenant,
  tenantSlugsById: null,
});

export const mutations = {
  setToken(state, { token }) {
    state.token = token;
  },

  setAdmin(state, admin) {
    Object.assign(state, { ...state, ...admin });
  },

  setTenantsList(state, tenants) {
    state.tenantsList = tenants;
  },

  setCurrentTenant(state, tenant) {
    state.currentTenant = tenant;
  },

  setTenantSlugsById(state, tenants) {
    if (state.tenantSlugsById) return;

    state.tenantSlugsById = tenants.reduce((acc, { id, slug }) => {
      acc[id] = slug;
      return acc;
    }, {});
  },
};

export const actions = {
  updateToken({ commit }, { token }) {
    TokenService.setToken(token);
    commit('setToken', { token });
  },

  updateId({ commit }, { adminId }) {
    localStorage.setItem('adminId', adminId);
    commit('setAdmin', { id: adminId });
  },

  updateCurrentTenant({ commit, dispatch }, tenant) {
    const tenantJson = JSON.stringify(tenant);
    localStorage.setItem(TENANT_LOCAL_STORAGE_KEY, tenantJson);

    dispatch('Whatsapp/tenantUpdated', null, { root: true });

    commit('setCurrentTenant', tenant);
  },

  updateCurrentTenantById({ dispatch, state }, id) {
    if (state.currentTenant.id === id) return;

    const tenant = state.tenantsList.find(tenant => tenant.id === id);

    dispatch('updateCurrentTenant', tenant);
  },

  updateCurrentTenantBySlug({ dispatch, state }, slug) {
    const { currentTenant } = state;

    if (currentTenant && currentTenant.slug === slug) return;

    const tenant = state.tenantsList.find(tenant => tenant.slug === slug);

    dispatch('updateCurrentTenant', tenant);
  },

  async loadTenants({ state, commit, dispatch }) {
    let response;

    try {
      response = await TenantAPI.getTenants();
    } catch (error) {
      const message = 'Erro ao carregar os tenants do servidor';
      dispatch('StoreManager/notify', { message }, { root: true });
      return;
    }

    const { tenants } = response.data;
    commit('setTenantsList', tenants);
    commit('setTenantSlugsById', tenants);

    if (!state.currentTenant) {
      dispatch('updateCurrentTenantBySlug', DEFAULT_TENANT_SLUG);
    }
  },
};

export const getters = {
  getToken: state => state.token,

  isAuthenticated: state => state.token !== null,

  getId: state => state.id,

  getCurrentTenant: state => state.currentTenant,

  tenantSlugsById: state => state.tenantSlugsById,

  loadingInitialData: state =>
    !state.email || !state.tenantsList || !state.currentTenant,
};

function getParsedJsonFromLocalStorage(key) {
  const json = localStorage.getItem(key);

  if (!json) return undefined;

  return JSON.parse(json);
}
