<template lang="pug">
  .profile-actions
    Button.profile-actions__btn(
      :disabled="!canRegister"
      title="\\+R"
      type="action"
      v-keycut:r.click=""
      @click="registerClicked"
    )
      .profile-actions__btn-inner
        Icon.profile-actions__icon(svg-name="i-balloon-checked")
        span Registrar

    Button.profile-actions__btn(
      :disabled="!canTransfer"
      title="\\+T"
      type="action"
      v-keycut:t.click=""
      @click="transferClicked"
    )
      .profile-actions__btn-inner
        Icon.profile-actions__icon(svg-name="i-forward")
        span Transferir

    Button.profile-actions__btn(
      :disabled="canUseOnlyBasicTools"
      title="\\+B"
      type="action"
      v-keycut:b.click=""
      @click="searchCourseClicked"
    )
      .profile-actions__btn-inner
        Icon.profile-actions__icon(svg-name="i-school")
        span Buscar Curso

    .opa-icon__container(tabindex="0" role="button" ref="profileMore")
        Popover.profile-actions-more-popover(openOn="focus" :mirrorEl="()=>$refs.profileMore" :toggleableFocus="true")
          Icon.opa-icon.profile-actions-more-icon(svgName="i-more-vertical" ref="more")
          template(slot="popover")
            Tooltip(vposition="down" hposition="right")
              TooltipList(:list="menuList")
                template(slot-scope="{ item }")
                  a.profile-actions__menu-link(
                    @click="menuClicked(item.key)"
                  )
                    Icon.profile-actions__menu-icon(:svgName="item.icon")
                    | {{ item.text }}
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Popover from '@/components/common/Popover';
import Tooltip from '@/components/common/Tooltip';
import TooltipList from '@/components/common/TooltipList';

export default {
  components: {
    Button,
    Icon,
    Tooltip,
    Popover,
    TooltipList,
  },
  computed: {
    ...mapGetters('Profile', [
      'user',
      'canTransfer',
      'canRegister',
      'hasLogin',
    ]),
    ...mapGetters('Permissions', [
      'canMergeUsers',
      'canManageVoucher',
      'canUseOnlyBasicTools',
    ]),
    ...mapState('Profile', ['profile']),
    menuList() {
      return [
        {
          icon: 'i-eye',
          text: 'Impersonar Aluno',
          key: 'impersonate',
          enabled: this.hasLogin,
        },
        {
          icon: 'i-people',
          text: 'Unificar Usuários',
          key: 'merge-user',
          enabled: this.canMergeUsers,
        },
        {
          icon: 'i-voucher',
          text: 'Vouchers do aluno',
          key: 'manage-voucher',
          enabled: this.canManageVoucher && this.hasVoucher,
        },
        {
          icon: 'i-lock',
          text: 'Redefinir Senha',
          key: 'password-recovery',
          enabled: this.canPasswordRecovery,
        },
        {
          icon: 'i-user-check',
          text: 'Reativar Conta',
          key: 'reactivate-user',
          enabled: this.canReactivateUser,
        },
      ].filter(x => x.enabled);
    },
    hasVoucher() {
      return this.profile.hasVoucher;
    },
    canPasswordRecovery() {
      const logins = this.profile?.logins || [];

      return logins.some(item => !!item.login_email);
    },
    canReactivateUser() {
      return this.profile?.destroyedAt;
    },
  },
  methods: {
    ...mapActions('RightPanel', ['tabChange']),
    ...mapActions('Profile', [
      'searchCourseClicked',
      'transferClicked',
      'registerClicked',
    ]),
    menuClicked(key) {
      if (key === 'impersonate') {
        return window.open(`/impersonate/${this.profile.id}`, '_blank');
      }

      return this.tabChange({ key });
    },
  },
};
</script>

<style lang="sass">
.profile-actions
  display: flex
  padding: 0 $space-m
  justify-content: space-between

.profile-actions__btn
  margin-bottom: $space-xxs

.profile-actions__btn-inner
  display: flex
  align-items: center

  & > span
    margin-left: $space-xs

.profile-actions__icon
  +square(15px)

.profile-actions-more-popover
  display: flex

.profile-actions__menu-link
  color: $silver-gray !important
  white-space: nowrap
  display: flex
  align-items: left
  padding: $space-s $space-st
  margin-right: $space-st
  text-decoration: none

.profile-actions__menu-icon
  margin-right: $space-m
</style>
